import Vue from 'vue';

import SocialiteProvider from '@/enums/SocialiteProvider';
import { i18n } from '@/i18n';
import store from '@/store';

export default async function googleOneTap(response) {
  await store.dispatch('socialite/authenticate', {
    provider_type: SocialiteProvider.googleOneTap.value,
    access_token: response.credential,
    platform: 'WEB',
  })
    .then(async () => {
      if (await store.state.$auth.authenticate()) {
        await store.dispatch('checkAuthAndUser');
        Vue.prototype.$analytics.trackSignIn(SocialiteProvider.googleOneTap.value);

        store.$app.$toast(i18n.t('login.welcome-back'));
      } else if (await store.getters['socialite/isOpenForRegistration']) {
        store.dispatch('authModal/setAuthModalMode', 'socialite-register');
      }
    })
    .catch((error) => {
      if (error?.response?.data?.data?.requires_verification) {
        store.dispatch('authModal/setAuthModalMode', 'socialite-register');
        store.commit('emailVerification/setSocialite', {
          provider_type: SocialiteProvider.googleOneTap.value,
          access_token: response.credential,
        });
        store.commit('setXsrfToken', error.response);
        store.commit('emailVerification/showEmailVerification', true);
      } else {
        store.$app.$toast(i18n.t('login.errors.something-went-wrong-with-social-connection'), {
          status: 'error',
        });
      }
    });
}
